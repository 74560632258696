import React from "react"
import SEO from "./SEO"
import Header from "./Header";
import Footer from "./Footer";
import headerImage from "../images/header-2.png"


const Layout = ( { children } ) => {
    return (
        <>
            <SEO/>
            <div className="leading-normal tracking-normal text-gray-400 m-0 p-6 bg-cover bg-fixed"
                 style={ { backgroundImage : `url(${ headerImage })` } }>
                <Header/>
                { children }
                <Footer/>
            </div>
        </>
    )
}

export default Layout
