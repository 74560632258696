import React from "react"

const Header = ({ children }) => {
  return (
    <>
        <header className="w-full container mx-auto">
            <div className="w-full flex flex-col items-center justify-between">
                <a className="flex items-center text-red-600 no-underline hover:no-underline font-bold"
                   href="https://NorthMetroTAFE.wa.edu.au">
                    Dev @ ScreenCraft
                </a>
            </div>
        </header>
    </>
  )
}

export default Header
