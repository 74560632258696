import React from "react"


const Layout = ( { children } ) => {
    return (
        <>
            <footer className="w-full container mx-auto">
                <div className="w-full flex flex-col items-center justify-between pt-16 pb-6 text-sm text-centerfade-in">
                    <a className="text-gray-500 no-underline hover:no-underline hover:text-red-500"
                       href="https://NorthMetroTAFE.wa.edu.au">
                        &copy; 2021, Web and Software development Lecturers at North Metropolitan TAFE
                    </a>
                </div>
            </footer>
        </>
    )
}

export default Layout
